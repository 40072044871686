/* Don't Usecontainer', use 'innerDiv' since we're adding custom padding.  */
/* Use 'xs' for mobile devices, it's a custom breakpoint added in tailwind.config.js  */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	font-family: 'Poppins', sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

span {
	vertical-align: middle;
}

.listContainer {
	overflow: hidden;
	-webkit-transform: rotate(-3deg) translateX(50%);
	-ms-transform: rotate(-3deg) translateX(50%);
	position: relative;
	left: -53%;
}

.listContainer .listContainerItem {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 50px;
}

.listContainer .listContainerItem li {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 170px;
	height: 190px;
	margin-right: 15px;
	overflow: hidden;
}

.listContainer .listContainerItem li .imgOverly {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: end;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
}

.listContainer .listContainerItem li span {
	display: block !important;
}

.listContainer .listContainerItem li img {
	border-radius: 10px;
}

.breadcrumbSection {
	background: url('../images/search-bg.png');
	background-size: cover;
}

.img-overly span {
	display: block !important;
}

.img-overly:after {
	content: '';
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
}

/* Tablet (xs)  */
@media only screen and (min-width: 280px) {
	.innerDiv {
		padding-left: 30px;
		padding-right: 30px;
	}
	.slider-section .card img {
		width: 120px !important;
		height: 120px !important;
	}
	header .innerDiv {
		padding-top: 2px;
		padding-bottom: 2px;
	}
	.listContainer .listContainerItem {
		margin-bottom: 0;
	}
	.listContainer .listContainerItem li {
		width: 120px;
		height: 135px;
	}
}

/* Tablet (sm)  */
@media only screen and (min-width: 640px) {
	.innerDiv {
		padding-left: 60px;
		padding-right: 60px;
	}
	header .innerDiv {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.listContainer .listContainerItem {
		margin-bottom: 0;
	}
	.listContainer .listContainerItem li {
		width: 120px;
		height: 135px;
	}
}

/* Tablet (md)  */
@media only screen and (min-width: 768px) {
	.innerDiv {
		padding-left: 60px;
		padding-right: 60px;
	}
	header .innerDiv {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

/* Desktop (lg) */
@media only screen and (min-width: 1024px) {
	.innerDiv {
		padding-left: 80px;
		padding-right: 80px;
	}
	.NavContainer {
		display: flex !important;
	}
	header .innerDiv {
		padding-top: 0;
		padding-bottom: 0;
	}
	.listContainer .listContainerItem {
		margin-bottom: 30px;
	}
	.listContainer .listContainerItem li {
		width: 150px;
		height: 170px;
	}
}

/* Desktop (xl)  */
@media only screen and (min-width: 1280px) {
	.innerDiv {
		max-width: calc(1280px + 160px);
		margin: 0 auto;
		padding-left: 80px;
		padding-right: 80px;
	}
	header .innerDiv {
		padding-top: 0;
		padding-bottom: 0;
	}
}

/* Desktop (2xl)  */
@media only screen and (min-width: 1536px) {
	.innerDiv {
		max-width: 1280px;
		margin: 0 auto;
		padding-left: 0;
		padding-right: 0;
	}
	header .innerDiv {
		padding-top: 0;
		padding-bottom: 0;
	}
}
@media (max-width: 767px) {
	.mobileSearch {
		position: fixed;
		top: 0;
		z-index: 9999;
		left: 0;
		right: 0;
		height: 100vh;
		border-radius: 0;
		display: block;
		padding: 15px;
	}
	.mobileSearch .searchIcon,
	.mobileSearch .searchBtn {
		display: none;
	}
	.mobileSearch .mobile-search-icons {
		display: flex;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}
}

.footerLink {
	display: block !important;
}
.active-tab{
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}
.tab-main{
	box-shadow: 0px 0px 40px rgba(112, 144, 176, 0.1);
}
.cover-bg span{
	display:block !important;
}
.cover-bg > span img{
	min-height:250px !important;
	object-fit:cover;
}